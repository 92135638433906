import React, { useState, useRef, useEffect, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { Stack } from '@hub/stack'
import {
  As,
  useBreakpointValue,
  HubResponsiveArray,
  HubStyleObject,
  StandardSpacings,
  forwardRef,
  useTheme,
  getToken,
  DEFAULT_BREAKPOINT,
} from '@hub/design-system-base'
import { useMergeRefs } from '@chakra-ui/react'

export type NamedScrollPosition = 'start' | 'middle' | 'end' | 'full'

const DEFAULT_CONTAINER_OVERFLOW = 'spacing-none'
const DEFAULT_GAP = 'spacing-none'
const DEFAULT_PEEK_WIDTH = 'spacing-none'
const DEFAULT_CENTERED = false
const DEFAULT_SNAP = false
const DEFAULT_INCLUDE_OVERFLOW = false

/**
 * We've got a few incoming arrays which may all be different lengths. We want
 * to grow them all to be the same length by duplicating the last element as
 * many times as necessary.
 * For example,
 *     extendToLargestArity(
 *       [1, 2],
 *       [3, 4, 5, 6],
 *       [7, 8, 9]
 *     )
 * will return
 *     [
 *       [1, 2, 2, 2],
 *       [3, 4, 5, 6],
 *       [7, 8, 9, 9]
 *     ]
 */
function extendToLargestArity<T extends unknown[][]>(...arrays: T) {
  // Figure out the longest arity
  const targetArity = Math.max.apply(
    Math,
    arrays.map(arr => arr.length)
  )

  return arrays.map(arr => {
    // Already the correct length
    if (arr.length === targetArity) {
      return arr
    }

    // Create an array enough to "top up" the original array, and initialise its
    // values with the same value as the last item in the original array
    const extendedArr = Array.from(
      { length: targetArity - arr.length },
      () => arr[arr.length - 1]
    )

    // The result is the combination of those two arrays
    return [...arr, ...extendedArr]
  }) as T
}

/**
 * Walk backwards through an array representing responsive values to find a
 * concrete value for the given index.
 */
function getValueFromResponsiveArray<T = any>(
  values: T[],
  index: number
): T | undefined {
  let stopIndex = index

  while (stopIndex >= 0) {
    const value = values[stopIndex]

    if (value != null) {
      return value
    }
    stopIndex -= 1
  }

  return undefined
}

export interface RenderControlsArgs {
  /**
   * A set of props to apply to a "Previous Button" component. Will handle
   * scrolling on click by default. It is recommended to spread these onto the
   * component. Alternatively, you can pick individual props to apply or
   * overwrite.
   */
  prevComponentProps: {
    /**
     * A click handler for the element which represents the Previous Button.
     * Will handle the logic of scrolling the <Reel>.
     */
    onClick: () => void
  }
  /**
   * A set of props to apply to a "Next Button" component. Will handle scrolling
   * on click by default. It is recommended to spread these onto the component.
   * Alternatively, you can pick individual props to apply or overwrite.
   */
  nextComponentProps: {
    /**
     * A click handler for the element which represents the Next Button. Will
     * handle the logic of scrolling the <Reel>.
     */
    onClick: () => void
  }
  /**
   * Updated whenever the scroll position changes from start, middle, or end.
   */
  scrollPosition: NamedScrollPosition
  /**
   * Set current scroll index, updated whenever the scroll position changes
   */
  scrollIndex: number
  /**
   * Imperatively scroll the Reel in the "Previous" direction (left). Not needed
   * when prevComponentProps.onClick is applied to an element.
   */
  scrollPrev: () => void
  /**
   * Imperatively scroll the Reel in the "Next" direction (right). Not needed
   * when nextComponentProps.onClick is applied to an element.
   */
  scrollNext: () => void
  /**
   * Whether there are frames which are overflowing the container (ie; causing a
   * scroll)
   */
  hasOverflowingFrames: boolean
  /**
   * Total number of frames detected for the Reel
   */
  totalFrames: number
  /**
   * The elements of the Reel required to be rendered.
   */
  children: React.ReactElement
}

export type RenderControls = (args: RenderControlsArgs) => React.ReactElement

export interface ReelProps {
  as?: As
  className?: string

  /**
   * Use CSS snap scrolling to snap the scroll position to the edge of a frame
   */
  snap?: HubResponsiveArray<boolean>

  /**
   * Children will be given a `maxWidth` prop which ensures this number of
   * children are visible at one time. Excess children will overflow to the
   * sides.
   * If there are not enough children to fill the visible frames, remaining
   * items will be centered.
   * Fractional numbers will be rounded down to the nearest whole number. To
   * show parts of another frame, see `peekWidth`.
   */
  visibleFrames: number | number[]

  /**
   * Spacing between each frame.
   */
  gap?: HubResponsiveArray<StandardSpacings>

  /**
   * How far adjacent frames overflow outside the edges of the reel. Uses the
   * `theme.space` scale.
   * Effectively creates a negative left/right margin to make the container
   * slightly larger without impacting the final size of the reel frames.
   */
  containerOverflow?: HubResponsiveArray<StandardSpacings>

  /**
   * How far adjacent frames overflow the left edge of the reel. Uses the
   * `theme.space` scale.
   * Effectively creates a negative left margin to make the container
   * slightly larger without impacting the final size of the reel frames.
   */
  containerOverflowLeft?: HubResponsiveArray<StandardSpacings>

  /**
   * How far adjacent frames overflow the right edge of the reel. Uses the
   * `theme.space` scale.
   * Effectively creates a negative right margin to make the container
   * slightly larger without impacting the final size of the reel frames.
   */
  containerOverflowRight?: HubResponsiveArray<StandardSpacings>

  /**
   * When determining a frame's width, the available width of the parent element
   * is divided evenly amongst each frame.
   * When `true`, if
   * `containerOverflowLeft` or `containerOverflowRight` are set, the width of
   * the parent is effectively increased, and therefore the width of each frame
   * is also increased. ie; frame width = `(100% + overflow left + overflow
   * right) / visibleFrames`.
   * When `false`, any `containerOverflowLeft` or `containerOverflowRight` is
   * ignored in determining the width of the individual frames. ie; frame width
   * = `100% / visibleFrames`.
   */
  includeOverflowInWidthCalculation?: boolean | boolean[]

  /**
   * How much of adjacent frames should peek out. Can be either a percentage of
   * a frame width (0.0 -> 1.0), or a StandardSpacings size.
   * Note this is different to `containerOverflow` as it determines how much of
   * a frame should be visible _within_ the container. The effect is achieved
   * by scaling down each frame so that part of one is visible in the scroll
   * direction.
   */
  peekWidth?: HubResponsiveArray<StandardSpacings | number>

  /**
   * Are slides centered? If not, left-aligned. When centered, `visibleFrames`
   * must be an odd number. If an even number is received, it will be rounded up
   * to the next odd number (eg; if you specify `2`, it will be rounded up to
   * `3`).
   */
  centered?: boolean | boolean[]

  sx?: HubStyleObject

  /**
   * Just like wrapping `<Reel>` in another component, but with some useful
   * methods and values you can use to selectively render controls, etc.
   */
  renderControls?: RenderControls

  children?:
    | React.ReactElement<{ sx?: HubStyleObject }>
    | React.ReactNode
    | (React.ReactElement<{ sx?: HubStyleObject }> | React.ReactNode)[]
}

function calculateScrollDistance(
  reelRef: React.RefObject<HTMLElement> | null,
  direction: 'prev' | 'next'
): number {
  const childWidth = reelRef?.current?.children[0]?.clientWidth || 0
  const elementViewportWidth = reelRef?.current?.clientWidth || 0
  const numberOfItemsOnScreenCompletely =
    (elementViewportWidth / childWidth) >> 0
  const scrollAmount = numberOfItemsOnScreenCompletely * childWidth
  let maxScroll = 0
  if (direction === 'next') {
    maxScroll =
      (reelRef?.current?.scrollWidth || 0) -
      ((reelRef?.current?.scrollLeft || 0) +
        (reelRef?.current?.clientWidth || 0))
  } else {
    maxScroll = reelRef?.current?.scrollLeft || 0
  }
  return maxScroll < scrollAmount ? maxScroll : scrollAmount
}

function getNamedScrollPosition(
  reelRef: React.RefObject<HTMLElement>,
  scrollOffset = 0
): NamedScrollPosition {
  // NOTE: 1px check is to account for some scroll containers not being able to
  // fully scroll the entire way to the edge (seen on Brave on MacOS)
  const isStart = (reelRef.current?.scrollLeft ?? 0) + scrollOffset <= 1

  const isEnd =
    (reelRef.current?.scrollLeft ?? 0) +
      (reelRef.current?.clientWidth ?? 0) +
      scrollOffset >=
    // NOTE: 1px check is to account for some scroll containers not being able
    // to fully scroll the entire way to the edge (seen on Brave on MacOS)
    (reelRef.current?.scrollWidth ?? 0) - 1

  if (isStart && isEnd) {
    return 'full'
  }
  if (isStart) {
    return 'start'
  }
  if (isEnd) {
    return 'end'
  }
  return 'middle'
}

export const Reel = forwardRef<ReelProps, typeof Stack>(
  (
    {
      as,
      minWidth = 'size-auto',
      maxWidth = 'size-auto',
      gap = DEFAULT_GAP,

      visibleFrames,
      containerOverflow = DEFAULT_CONTAINER_OVERFLOW,
      containerOverflowLeft = containerOverflow,
      containerOverflowRight = containerOverflow,
      includeOverflowInWidthCalculation = DEFAULT_INCLUDE_OVERFLOW,
      peekWidth = DEFAULT_PEEK_WIDTH,
      centered = DEFAULT_CENTERED,

      className,
      snap = DEFAULT_SNAP,
      children,
      renderControls = ({ children }) => children,
      sx,
    },
    externalReelRef
  ) => {
    const [scrollPosition, setScrollPosition] =
      useState<NamedScrollPosition>('start')
    const [scrollIndex, setScrollIndex] = useState(0)
    const localReelRef = useRef<HTMLElement>(null)

    const scrollPrev = useCallback(() => {
      const scrollAmount = -calculateScrollDistance(localReelRef, 'prev')
      localReelRef?.current?.scrollBy(scrollAmount, 0)
    }, [])

    const scrollNext = useCallback(() => {
      const scrollAmount = calculateScrollDistance(localReelRef, 'next')
      localReelRef?.current?.scrollBy(scrollAmount, 0)
    }, [])

    useEffect(() => {
      const handleScroll = debounce((): void => {
        setScrollPosition(getNamedScrollPosition(localReelRef))
        if (localReelRef) {
          const scrollDistance = Math.abs(
            calculateScrollDistance(
              localReelRef,
              scrollPosition === 'start' ? 'next' : 'prev'
            )
          )
          const scrollIndex = Math.round(
            (localReelRef?.current?.scrollLeft || 0) / scrollDistance
          )
          if (!isNaN(scrollIndex)) {
            setScrollIndex(scrollIndex)
          }
        }
      }, 100)

      // Important to capture the element here so when the effect is unmounted
      // we can remove the event listener from the correct element instead of
      // any possible future value of `.current`
      const reelEl = localReelRef.current

      // Will be called for both a user-scroll and for when `scrollPrev` /
      // `scrollNext` are called
      reelEl?.addEventListener('scroll', handleScroll)

      handleScroll()
      return () => {
        // Cancel any pending debounced method calls
        handleScroll.cancel()
        // And remove the handler from the DOM
        reelEl?.removeEventListener('scroll', handleScroll)
      }
    }) // no dependancies because otherwise it doesn't work with SSR

    // Ensure we respect any ref the user might have passed in
    const reelRef = useMergeRefs<HTMLElement>(externalReelRef, localReelRef)

    const theme = useTheme()

    // We've got a few incoming arrays which may all be different lengths. To
    // make the following code easier, we want to grow them all to be the same
    // length by duplicating the last element as many times as necessary
    let [
      visibleFramesArray,
      gapArray,
      containerOverflowLeftArray,
      containerOverflowRightArray,
      peekWidthArray,
      centeredArray,
      snapArray,
      includeOverflowInWidthCalculationArray,
    ] = extendToLargestArity(
      Array.isArray(visibleFrames) ? visibleFrames : [visibleFrames],
      Array.isArray(gap) ? gap : [gap],
      Array.isArray(containerOverflowLeft)
        ? containerOverflowLeft
        : [containerOverflowLeft],
      Array.isArray(containerOverflowRight)
        ? containerOverflowRight
        : [containerOverflowRight],
      Array.isArray(peekWidth) ? peekWidth : [peekWidth],
      Array.isArray(centered) ? centered : [centered],
      Array.isArray(snap) ? snap : [snap],
      Array.isArray(includeOverflowInWidthCalculation)
        ? includeOverflowInWidthCalculation
        : [includeOverflowInWidthCalculation]
    )

    // Sanitise the data passed in for visibleFrames
    visibleFramesArray = visibleFramesArray.map((frames, index) => {
      const wholeFrames = Math.floor(frames)
      const centered =
        getValueFromResponsiveArray(centeredArray, index) ?? DEFAULT_CENTERED
      // When centering, must be an odd number, so we round up if necessary
      if (centered && wholeFrames % 2 === 0) {
        return wholeFrames + 1
      }
      return wholeFrames
    })

    const totalFrames = React.Children.count(children)

    // An array of max widths which take into consideration both spacing and
    // visible frames
    let maxWidths: string[] = []
    let scrollSnaps: string[] = []

    // The container width might need adjusting if there is overflow
    let containerWidths: string[] = []

    // When overflowing to the left, add a negative left margin.
    // NOTE: We don't add a negative right margin, instead we adjust the width
    // of the container above
    let containerMarginLefts: string[] = []

    // When there's no container overflow, this does nothing. But when there's
    // overflow, we need to push all the contents of the scrollable container
    // over a bit so it lines up with where it would have originally been.
    let leftOverscroll: string[] = []

    // Added to fix mobile and safari issue when scrollPaddingInlineStart gets ignored
    let scrollPaddingLeft: string[] = []

    // When there's no container overflow, this does nothing. But when there's
    // overflow, this acts as a soft of over-scroll.
    let rightOverscroll: string[] = []
    let containerScrollSnapType: string[] = []

    for (let index = 0; index < visibleFramesArray.length; index++) {
      const visibleFramesForBreakpoint =
        getValueFromResponsiveArray(visibleFramesArray, index) ?? totalFrames

      const minTotalFrames =
        totalFrames < visibleFramesForBreakpoint
          ? visibleFramesForBreakpoint
          : totalFrames

      const containerOverflowLeftForBreakpoint =
        getValueFromResponsiveArray(containerOverflowLeftArray, index) ??
        DEFAULT_CONTAINER_OVERFLOW

      const containerOverflowRightForBreakpoint =
        getValueFromResponsiveArray(containerOverflowRightArray, index) ??
        DEFAULT_CONTAINER_OVERFLOW

      const gapForBreakpoint =
        getValueFromResponsiveArray(gapArray, index) ?? DEFAULT_GAP

      const peekWidthForBreakpoint =
        getValueFromResponsiveArray(peekWidthArray, index) ?? DEFAULT_PEEK_WIDTH

      const centeredForBreakpoint =
        getValueFromResponsiveArray(centeredArray, index) ?? DEFAULT_CENTERED

      const snapForBreakpoint =
        getValueFromResponsiveArray(snapArray, index) ?? DEFAULT_SNAP

      const hasContainerOverflowLeft =
        containerOverflowLeftForBreakpoint &&
        containerOverflowLeftForBreakpoint !== 'spacing-none'

      const hasContainerOverflowRight =
        containerOverflowRightForBreakpoint &&
        containerOverflowRightForBreakpoint !== 'spacing-none'

      const includeOverflowInWidthCalculationForBreakpoint =
        getValueFromResponsiveArray(
          includeOverflowInWidthCalculationArray,
          index
        ) ?? DEFAULT_INCLUDE_OVERFLOW

      let hasConcretePeek = false
      let hasFractionalPeek = false

      let concretePeekWidthCss = '0px'
      let peekWidthForBreakpointFraction = 0

      if (
        typeof peekWidthForBreakpoint !== 'number' &&
        peekWidthForBreakpoint &&
        peekWidthForBreakpoint !== 'spacing-none'
      ) {
        hasConcretePeek = true
        ;[concretePeekWidthCss] = getToken(theme, 'space', [
          peekWidthForBreakpoint,
        ])
      } else if (
        typeof peekWidthForBreakpoint === 'number' &&
        peekWidthForBreakpoint
      ) {
        hasFractionalPeek = true
        // Consider only numbers from 0.0 -> 0.99 inclusive
        peekWidthForBreakpointFraction =
          Math.round((peekWidthForBreakpoint + Number.EPSILON) * 100) / 100
      }

      // Convert the incoming responsive props into concrete css values based on
      // the theme, so we can use them later in our calculations
      const [gapCss] = getToken(theme, 'space', [gapForBreakpoint])

      const [containerOverflowLeftCss] = hasContainerOverflowLeft
        ? getToken(theme, 'space', [containerOverflowLeftForBreakpoint])
        : ['0px']

      const [containerOverflowRightCss] = hasContainerOverflowRight
        ? getToken(theme, 'space', [containerOverflowRightForBreakpoint])
        : ['0px']

      // How many frames are actually visible
      const frames = Math.min(visibleFramesForBreakpoint, minTotalFrames)

      // By default, spaces are _between_ frames, so there is one less space
      // than visible frames
      let spacesBetweenFrames = frames - 1

      // By default assume there's no peeking
      let peekWidthForBreakpointCalc = '0px'

      // There are overflowing frames, so we need to adjust everything
      if (minTotalFrames > visibleFramesForBreakpoint) {
        // When frames are peeking out from the sides, we need to account for
        // extra spacing.
        if (hasConcretePeek || hasFractionalPeek) {
          // When centered, there's an extra space on both left & right
          if (centeredForBreakpoint) {
            spacesBetweenFrames += 2
          } else {
            // When left-aligned (ie; not centered), there's only an extra space
            // on the right
            spacesBetweenFrames += 1
          }
        }

        if (hasConcretePeek) {
          // When centered, peeking on both left & right
          if (centeredForBreakpoint) {
            peekWidthForBreakpointCalc = `${concretePeekWidthCss} * 2`
          } else {
            // When left-aligned (ie; not centered), there's only overflow right
            peekWidthForBreakpointCalc = concretePeekWidthCss
          }
        }
      }

      // Add up all the spacing
      const allTheSpacing = `(${[
        // The cumulative gap between all the frames
        `${gapCss} * ${spacesBetweenFrames}`,

        // The left overflow needs to be taken into account as a space
        !includeOverflowInWidthCalculationForBreakpoint &&
          hasContainerOverflowLeft &&
          containerOverflowLeftCss,

        // The right overflow needs to be taken into account as a space
        !includeOverflowInWidthCalculationForBreakpoint &&
          hasContainerOverflowRight &&
          containerOverflowRightCss,

        // How much one of the frames is "peeking" is counted as another space
        peekWidthForBreakpointCalc,
      ]
        .filter(Boolean)
        .join(' + ')})`

      // And remove that from the total width
      const widthWithoutSpacing = `(100% - ${allTheSpacing})`

      const framesToShow =
        hasFractionalPeek && minTotalFrames > visibleFramesForBreakpoint
          ? // Account for the extra visible frames here when a fraction is passed
            // for the peekWidthForBreakpoint
            frames +
            peekWidthForBreakpointFraction * (centeredForBreakpoint ? 2 : 1)
          : // Just use the whole number as we've already accounted for the extra
            // visible frames in the above spacing calculation, given that it's a
            // concrete CSS value
            frames

      // Finally, the size of each frame is calculated. Phew!
      maxWidths.push(`calc(${widthWithoutSpacing} / ${framesToShow})`)

      // When there are overflowing frames, _and_ the developer has set an
      // overflow value, we need to adjust the edges of the container to grow
      const containerWidth = [
        hasContainerOverflowLeft && containerOverflowLeftCss,
        hasContainerOverflowRight && containerOverflowRightCss,
      ]
        .filter(Boolean)
        .join(' + ')

      containerWidths.push(
        containerWidth ? `calc(100% + ${containerWidth})` : '100%'
      )

      containerMarginLefts.push(
        // NOTE: We do this inside a calc in case the value is something like
        // `min(...)`, which would result in invalid CSS `-min(...)`
        hasContainerOverflowLeft
          ? `calc(0px - ${containerOverflowLeftCss})`
          : '0px'
      )

      leftOverscroll.push(
        includeOverflowInWidthCalculationForBreakpoint
          ? '0px'
          : containerOverflowLeftCss
      )
      rightOverscroll.push(
        includeOverflowInWidthCalculationForBreakpoint
          ? '0px'
          : containerOverflowRightCss
      )

      scrollPaddingLeft.push(
        includeOverflowInWidthCalculationForBreakpoint
          ? '0px'
          : containerOverflowLeftCss
      )

      if (snapForBreakpoint) {
        scrollSnaps.push(centeredForBreakpoint ? 'center' : 'start')
        containerScrollSnapType.push('x mandatory')
      } else {
        scrollSnaps.push('none')
        containerScrollSnapType.push('none')
      }
    }

    // Figure out how many frames are visible for the current breakpoint
    const visibleFramesForBreakpoint = useBreakpointValue(
      visibleFramesArray,
      DEFAULT_BREAKPOINT
    ) as number
    const hasOverflowingFrames = totalFrames > visibleFramesForBreakpoint

    return renderControls({
      hasOverflowingFrames,
      scrollPrev,
      scrollNext,
      prevComponentProps: {
        onClick: scrollPrev,
      },
      nextComponentProps: {
        onClick: scrollNext,
      },
      scrollPosition,
      scrollIndex,
      totalFrames,
      children: (
        <Stack
          ref={reelRef}
          as={as}
          direction="row"
          className={className}
          gap={gapArray}
          shouldWrapChildren={false}
          // NOTE: Always justify left, even if "centered". "centered" only
          // changes how the individual frames snap when scrolling
          justify="left"
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            // NOTE: pseudo elements are not included in sibling selectors, so
            // this doesn't interfere with (or receive) the Stack's spacing
            // margins
            '&::before': {
              content: '""',
              display: 'block',
              width: leftOverscroll,
              minWidth: leftOverscroll,
            },
            '&::after': {
              content: '""',
              display: 'block',
              width: rightOverscroll,
              minWidth: rightOverscroll,
            },
            scrollPaddingInlineStart: leftOverscroll,
            scrollPaddingInlineEnd: rightOverscroll,
            scrollPaddingLeft: scrollPaddingLeft,
            msOverflowStyle: 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            overflowX: 'auto',
            scrollBehavior: 'smooth',
            scrollSnapType: containerScrollSnapType,
            width: containerWidths,
            marginLeft: containerMarginLefts,
            ...sx,
          }}
        >
          {React.Children.map(
            children,
            child =>
              React.isValidElement<ReelProps>(child) &&
              React.cloneElement(child, {
                sx: {
                  ...(visibleFrames !== 0 &&
                    (hasOverflowingFrames
                      ? {
                          minWidth: maxWidths,
                          width: maxWidths,
                        }
                      : {
                          maxWidth: maxWidths,
                        })),
                  scrollSnapAlign: scrollSnaps,
                  ...(child?.props?.sx ?? {}),
                },
              })
          )}
        </Stack>
      ),
    })
  }
)

import React from 'react'
import { Button } from '@hub/button'
import { Link } from '@hub/link'
import { As, HubStyleObject } from '@hub/design-system-base'

type StandardButtonProps = React.PropsWithChildren<{
  onClick?: () => void
  variant: 'solid' | 'outline' | 'transparent'
  isDisabled?: boolean
  href?: string
  ariaLabel?: string
  target?: string
  as?: As
  sx?: HubStyleObject
}>

export const StandardButton: React.FC<StandardButtonProps> = ({
  children,
  onClick,
  isDisabled,
  variant = 'solid',
  href,
  ariaLabel,
  target,
  as: As,
  sx,
}) => {
  const MaybeLink = href === undefined ? undefined : Link
  return (
    <Button
      as={As ?? MaybeLink}
      href={href}
      target={target}
      ariaLabel={ariaLabel}
      colorScheme="surfacePrimaryWithText"
      sx={{
        paddingX: 'spacing-sm',
        paddingY: 'spacing-md',
        textTransform: 'uppercase',
        minWidth: ['100%', null, 'size-13', 'size-13'],
        minHeight: 'size-8',
        textDecoration: 'none !important',
        ...sx,
      }}
      variant={variant}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  )
}
